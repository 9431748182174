import React, { Component } from "react";
import axios from "axios";

export default class Jobs extends Component {
    constructor(props) {
        super();

        this.state = {
            items: [],
            filter: props.filter,
            nodeId: props.nodeId,
            currentPage: props.currentPage,
            totalPages: 0,
            filters: props.filters,
            dictionary: props.dictionary,
            init: true
        }

        this.myRef = React.createRef();

        this.loadJobs = this.loadJobs.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
    }

    componentDidUpdate() {
        if((this.state.filter !== "" || this.state.currentPage > 1 ) && this.state.init){
            this.setState({init: false});
        }
        if(!this.state.init){
        this.myRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    componentDidMount() {
        this.loadJobs(this.state.currentPage);
    }

    loadJobs(page) {
        //this.setState({ selectedFilter: e.target.value });
        axios.get(`/umbraco/api/jobs/GetJobs?filter=${this.state.filter}&nodeId=${this.state.nodeId}&page=${page}`)
            .then((response) => {
                this.setState({
                    items: response.data.items,
                    totalPages: response.data.totalPages,
                    currentPage: page
                });
            })
            .catch((err) => console.error(err));
    }

    changeFilter(e, filter) {
        e.preventDefault();
        this.setState({
            filter: filter
        }, () => { this.loadJobs(1, e); });

    }

    renderPagination() {
        let element = [];

        if (this.state.totalPages > 1) {
            //show pages before current
            if (this.state.currentPage > 2) {
                for (let i = this.state.currentPage - 1; i > (this.state.currentPage - 4 < 1 ? 1 : this.state.currentPage - 4); i--) {
                    if (i !== 1 && i !== this.state.totalPages) {
                        element.unshift(
                            <li className="m-pagination__item"><button onClick={() => this.loadJobs(i)} className="m-pagination__link a-button a-button--icon a-button--sm">
                                {i}
                            </button></li>
                        )
                    }
                }
            }

            // show first page
            element.splice(0, 0,
                <li className="m-pagination__item"><button onClick={() => this.loadJobs(1)} className={"m-pagination__link a-button a-button--icon a-button--sm " + (this.state.currentPage === 1 ? "is-active" : "")}>
                    1
                </button></li>
            )

            // show start ellipsis
            if (this.state.currentPage >= 6) {
                element.splice(1, 0,
                    <li className="m-pagination__item">...</li>
                )
            }

            // show current page
            if (this.state.currentPage !== 1 && this.state.currentPage !== this.state.totalPages) {
                element.push(
                    <li className="m-pagination__item"><button onClick={() => this.loadJobs(this.state.currentPage)} className="m-pagination__link a-button a-button--icon a-button--sm is-active">
                        {this.state.currentPage}
                    </button></li>
                )
            }

            // show pages after current
            for (let j = this.state.currentPage + 1; j < (this.state.currentPage + 5 >= this.state.totalPages ? this.state.totalPages : this.state.currentPage + 5); j++) {
                if (j !== 1 && j !== this.state.totalPages) {
                    element.push(
                        <li className="m-pagination__item"><button onClick={() => this.loadJobs(j)} className="m-pagination__link a-button a-button--icon a-button--sm">
                            {j}
                        </button></li>
                    )
                }
            }

            // show end ellipsis
            if (this.state.currentPage < this.state.totalPages - 5) {
                element.push(
                    <li className="m-pagination__item">...</li>
                )
            }

            // show last page
            element.push(
                <li className="m-pagination__item"><button onClick={() => this.loadJobs(this.state.totalPages)} className={"m-pagination__link a-button a-button--icon a-button--sm " + (this.state.currentPage === this.state.totalPages ? "is-active" : "")}>
                    {this.state.totalPages}
                </button></li>
            )
        }

        return element;
    }

    render() {
        return (
            <React.Fragment>
                <div ref={this.myRef}>
                    <div class="m-button-group u-container u-padding-top-xxx">
                        {this.state.filters.map((name) =>
                            <a href="#" className={"a-button " + (this.state.filter === name ? "is-active" : "")} onClick={(e) => this.changeFilter(e, name)}>{name}</a>
                        )}
                    </div>
                    <section class="u-container u-padding-top-xx">
                        <h2 class="u-visually-hidden">Jobs</h2>
                        <ul class="o-careers-list">
                            {this.state.items !== undefined && this.state.items.map(item =>
                                <li className="o-careers-list__item"><article class="m-career-card">
                                    <a href={item.PageUrl} class="m-career-card__link u-padding" title={this.state.dictionary.goTo + " " + item.Name}>
                                        <div class="m-career-card__wrapper">
                                            {item.Name !== "" ?
                                                <h3 class="m-career-card__title">{item.Name}</h3>
                                                : ""
                                            }
                                            {item.Company !== "" ?
                                                <p class="m-career-card__company">{item.Company}</p>
                                                : ""}
                                        </div>

                                        {item.Location !== "" ?
                                            <p class="m-career-card__city">{item.Location}</p>
                                            : ""}

                                        <div class="m-career-card__button a-button">{this.state.dictionary.goToButton}</div>
                                    </a>
                                </article></li>
                            )}
                        </ul>
                    </section>
                    <div className="u-padding-top-lg">
                        <div className="m-pagination m-pagination--large">
                            <ul className="m-pagination__links">
                                {this.renderPagination()}
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
