import React, { Component } from "react";
import axios from "axios";

export default class Cases extends Component {
    constructor(props) {
        super();

        this.state = {
            items: [],
            filter: props.filter,
            nodeId: props.nodeId,
            currentPage: props.currentPage,
            totalPages: 0,
            filters: props.filters,
            dictionary: props.dictionary, 
            init:true
        }

        this.myRef = React.createRef();

        this.loadCases = this.loadCases.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
    }

    componentDidUpdate() {
        if((this.state.filter !== "" || this.state.page > 1 ) && this.state.init){
            this.setState({init: false});
        }
        if(!this.state.init){
            this.myRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    componentDidMount() {
        this.loadCases(this.state.currentPage);
     }

    loadCases(page) {
        //this.setState({ selectedFilter: e.target.value });
        axios.get(`/umbraco/api/case/GetCases?filter=${this.state.filter}&nodeId=${this.state.nodeId}&page=${page}`)
            .then((response) => {
                this.setState({
                    items: response.data.items,
                    totalPages: response.data.totalPages,
                    currentPage: page
                });
            })
            .catch((err) => console.error(err));
    }

    changeFilter(e) {
        this.setState({
            filter: e.target.value
        }, () => { this.loadCases(1, e); });

    }

    renderPagination() {
        let element = [];

        if (this.state.totalPages > 1) {
            //show pages before current
            if (this.state.currentPage > 2) {
                for (let i = this.state.currentPage - 1; i > (this.state.currentPage - 4 < 1 ? 1 : this.state.currentPage - 4); i--) {
                    if (i !== 1 && i !== this.state.totalPages) {
                        element.unshift(
                            <li className="m-pagination__item"><button onClick={() => this.loadCases(i)} className="m-pagination__link a-button a-button--icon a-button--sm">
                                {i}
                            </button></li>
                        )
                    }
                }
            }

            // show first page
            element.splice(0, 0,
                <li className="m-pagination__item"><button onClick={() => this.loadCases(1)} className={"m-pagination__link a-button a-button--icon a-button--sm " + (this.state.currentPage === 1 ? "is-active" : "")}>
                    1
                </button></li>
            )

            // show start ellipsis
            if (this.state.currentPage >= 6) {
                element.splice(1, 0,
                    <li className="m-pagination__item">...</li>
                )
            }

            // show current page
            if (this.state.currentPage !== 1 && this.state.currentPage !== this.state.totalPages) {
                element.push(
                    <li className="m-pagination__item"><button onClick={() => this.loadCases(this.state.currentPage)} className="m-pagination__link a-button a-button--icon a-button--sm is-active">
                        {this.state.currentPage}
                    </button></li>
                )
            }

            // show pages after current
            for (let j = this.state.currentPage + 1; j < (this.state.currentPage + 5 >= this.state.totalPages ? this.state.totalPages : this.state.currentPage + 5); j++) {
                if (j !== 1 && j !== this.state.totalPages) {
                    element.push(
                        <li className="m-pagination__item"><button onClick={() => this.loadCases(j)} className="m-pagination__link a-button a-button--icon a-button--sm">
                            {j}
                        </button></li>
                    )
                }
            }

            // show end ellipsis
            if (this.state.currentPage < this.state.totalPages - 5) {
                element.push(
                    <li className="m-pagination__item">...</li>
                )
            }

            // show last page
            element.push(
                <li className="m-pagination__item"><button onClick={() => this.loadCases(this.state.totalPages)} className={"m-pagination__link a-button a-button--icon a-button--sm " + (this.state.currentPage === this.state.totalPages ? "is-active" : "")}>
                    {this.state.totalPages}
                </button></li>
            )
        }

        return element;
    }

    render() {
        return (
            <section ref={this.myRef} class="o-cases">
                <div className="u-align-right u-padding-top-lg u-container">
                    <p>{this.state.selectedFilter}</p>
                    <div className="a-input">
                        <label className="u-visually-hidden" htmlFor="input-select">filter</label>
                        <div className="a-input__field-holder">
                            <select value={this.state.filter} onChange={(e) => this.changeFilter(e)} className="input-selected" id="input-select">
                                <option value="">{this.state.dictionary.nofilter}</option>
                                {this.state.filters.map((name) =>
                                    <option key={name} value={name}>{name}</option>
                                )}
                            </select>
                            <span className="icon-arrow-down"></span>
                        </div>
                    </div>
                </div>
                <div className="o-section u-container u-padding-top-lg">
                    <div className="o-teaser-list">
                        {this.state.items !== undefined && this.state.items.map(item =>
                            <a key={item.Name} href={item.PageUrl} title={item.Name} className="m-teaser">
                                {item.ImageUrl != null &&
                                    <div className="a-image a-image--cover m-teaser__image a-image--slanted">
                                        <picture>
                                            <source srcset={item.ImageUrl + "?width=670&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=1340&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=2010&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 1590px)"/>
                                            <source srcset={item.ImageUrl + "?width=670&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=1304&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=2010&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 1440px)"/>
                                            <source srcset={item.ImageUrl + "?width=580&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=1160&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=1740&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 1240px)"/>
                                            <source srcset={item.ImageUrl + "?width=520&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=1040&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=1560&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 1120px)"/>
                                            <source srcset={item.ImageUrl + "?width=420&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=840&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=1260&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 920px)"/>
                                            <source srcset={item.ImageUrl + "?width=340&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=680&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=1020&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 760px)"/>
                                            <source srcset={item.ImageUrl + "?width=580&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=1160&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=1740&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 640px)"/>
                                            <source srcset={item.ImageUrl + "?width=420&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=840&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=1260&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 480px)"/>
                                            <source srcset={item.ImageUrl + "?width=300&height=320" + item.ImageUrlCropSuffix + " 1x," + item.ImageUrl + "?width=600&height=640" + item.ImageUrlCropSuffix + " 2x," + item.ImageUrl + "?width=900&height=960" + item.ImageUrlCropSuffix + " 3x"} media="(min-width: 320px)"/>
                                            <img src={item.ImageUrl + "?width=300&height=320" +  item.ImageUrlCropSuffix} alt={item.Title} class="lazyload"/>
                                        </picture>
                                    </div>}

                                <div className="m-teaser__caption">
                                    <h3 className="a-title-md m-teaser__title">{item.Name}</h3>
                                    {item.Name != null &&
                                        <p className="m-teaser__description">{item.Description}</p>
                                    }
                                    {item.Tags != null &&
                                        <ul className="m-tag-group">
                                            {item.Tags.map(tag =>
                                                <li key={tag} className="m-tag-group__item">
                                                    <span className="a-tag">{tag}</span>
                                                </li>
                                            )}
                                        </ul>
                                    }
                                </div>
                            </a>
                        )}
                    </div>
                </div>
                <div className="u-padding-top-lg">
                    <div className="m-pagination m-pagination--large">
                        <ul className="m-pagination__links">
                            {this.renderPagination()}
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}
